import { useRouter } from 'next/router';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import WhySkyhook from '../about/WhySkyhook';
import ContentfulContent from '../Contentful/ContentfulContent';
import HeaderSection from '../HeaderSection';
import { SalesPopupProvider } from '../SalesPopupProvider';
import SeoMetaTags from '../seoMetaTags';
import TrustPilotWidget from '../trustpilot/TrustPilotWidget';
import LoadingPage from '../utilities/LoadingPage';
import NotFound from '../utilities/NotFound';

export type ContentFulPage = {
  slug: string;
  title: string;
  image: {
    url: string;
  };
  description: string;
  keywords: string[];
  metaTitle: string;
  content: {
    json: any;
    links: {
      assets: {
        block: {
          url: string;
          sys: {
            id: string;
          };
          title: string;
          description: string;
        }[];
      };
    };
  };
};

type PageContentProps = {
  page: ContentFulPage;
};

export default function StaticPageContent({ page }: PageContentProps) {
  const router = useRouter();

  // show loading page if the page hasn't been generated, fallback will generate
  // send it to the frontend
  if (router.isFallback) {
    return <LoadingPage />;
  }

  if (!page) {
    return <NotFound />;
  }

  return (
    <>
      <SeoMetaTags
        title={page.metaTitle || page.title}
        description={page.description}
        keywords={page.keywords ?? []}
        showWebsiteName={false}
        imageKey={page.image?.url}
        cdn={process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL}
      />

      {page.image?.url && (
        <HeaderSection title={page.title} image={page.image.url} />
      )}

      <style jsx>{`
        .breadcrumb {
          font-size: 16px;
        }
      `}</style>

      <section className="py-4 bg-gray-100">
        <Container>
          <TrustPilotWidget
            templateId="5419b6ffb0d04a076446a9af"
            height="20px"
            className="mx-auto"
          />
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col xs={12} sm={8} md={7} className="mx-auto pt-2">
              <Breadcrumb className="py-2">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{page.title}</Breadcrumb.Item>
              </Breadcrumb>
              <p className="lead">{page.description}</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid>
          <ContentfulContent document={page} />
        </Container>
      </section>

      <WhySkyhook />

      <SalesPopupProvider bottomPosition={10} />
    </>
  );
}
