import { useQuery } from '@tanstack/react-query';
import countries from '@website/dataObjects/countries';
import { GetSalesBookingQuery } from '@website/types/graphqlOperations';
import { DateTime } from 'luxon';

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export const timeAgo = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((u) => diff.get(u) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export default function useRecentBookings() {
  const { data } = useQuery({
    queryKey: ['recentBookings'],
    queryFn: async () => {
      const response = await fetch('/api/bookings/sales', {
        method: 'GET',
      });
      const body = await response.json();
      return body;
    },
    select: (bookings: GetSalesBookingQuery['recentPublicBookings']) =>
      bookings.map((booking) => ({
        when: timeAgo(DateTime.fromISO(booking.created)),
        name: booking.user.givenName,
        trip: booking.trip.title,
        link: `/trips/${booking.trip.slug}`,
        image: booking.trip.image.file.key,
        country: countries[booking.user?.address?.country],
      })),
  });

  return data;
}
