import useRecentBookings from '@website/hooks/useRecentBookings';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { MdOutlineClose } from 'react-icons/md';
import Imgix from 'react-imgix';
import { getImageUrl } from './media/utils/imgix';

type SalesPopupProps = {
  bottomPosition?: number;
};

export function SalesPopup({ bottomPosition = 80 }: SalesPopupProps) {
  const { push } = useRouter();
  const sales = useRecentBookings();
  const [isDismissedByCustomer, setIsDismissedByCustomer] = useState(false);
  const [lastIndex, setLastIndex] = useState(0);
  const [activeSale, setActiveSale] =
    useState<ReturnType<typeof useRecentBookings>[number]>(undefined);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const delay = 10000;

    const showNextToast = () => {
      const sale = sales[lastIndex];
      if (sale) {
        setActiveSale(sale);
      }
      setLastIndex((prev) => {
        if (prev + 1 >= sales.length) {
          return 0;
        }
        return prev + 1;
      });
    };

    if (activeSale) {
      return () => {};
    }

    timeout = setTimeout(showNextToast, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [push, sales, activeSale, isDismissedByCustomer, lastIndex]);

  useEffect(() => {
    if (!isDismissedByCustomer && activeSale) {
      setTimeout(() => {
        setActiveSale(undefined);
      }, 8000);
    }
  }, [activeSale, isDismissedByCustomer]);

  if (!activeSale) {
    return null;
  }

  return (
    <Toast
      style={{
        position: 'fixed',
        right: 10,
        bottom: bottomPosition,
        width: 400,
        maxWidth: '97%',
        zIndex: 9999,
      }}
      show={activeSale !== undefined && !isDismissedByCustomer}
      onClose={() => {
        setActiveSale(undefined);
        setIsDismissedByCustomer(true);
      }}
    >
      <div className="card">
        <div className="row no-gutters">
          <div
            className="col-4"
            style={{
              aspectRatio: '4/3',
            }}
          >
            <Link href={activeSale.link}>
              <Imgix
                src={getImageUrl(activeSale.image)}
                className="card-img h-100"
                imgixParams={{
                  ar: '4:3',
                  w: 240,
                  h: 180,
                  fit: 'crop',
                  crop: 'faces,edges',
                }}
                sizes="240px"
                height={150}
                width={240}
                htmlAttributes={{
                  alt: activeSale.name,
                }}
              />
            </Link>
          </div>
          <div className="col-8 py-2 justify-content-center align-items-center">
            <div className="block">
              {activeSale.name}
              {activeSale.country ? ` in ${activeSale.country}` : ''} booked
            </div>
            <div className="card-text">
              <Link
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                className="block fw-bold"
                href={activeSale.link}
              >
                {activeSale.trip}
              </Link>
            </div>
            <div className="card-text">{activeSale.when}</div>
            <div
              aria-roledescription="button"
              role="button"
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
              className="text-danger"
              onKeyDown={() => {
                setActiveSale(undefined);
                setIsDismissedByCustomer(true);
              }}
              onClick={() => {
                setActiveSale(undefined);
                setIsDismissedByCustomer(true);
              }}
            >
              <MdOutlineClose />
            </div>
          </div>
        </div>
      </div>
    </Toast>
  );
}

export function SalesPopupProvider(props: SalesPopupProps) {
  return <SalesPopup {...props} />;
}
